<template>
	<div>
		<CCol md="12" class="mb-4">
			<CRow align-horizontal="end">
				<router-link
					:to="{ name: 'ProductFamilyCreate' }"
					class="link-button"
				>
					<CButton class="btn-new-product-family transparent ml-3" color="secondary">
						<CIcon class="icon-left" name="cil-plus" />
						Create product family
					</CButton>
				</router-link>
			</CRow>
		</CCol>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						type="text"
						placeholder="Search by product family name or ID"
					>
						<template #prepend-content>
							<button class="btn-search" type="submit">
								<FontAwesomeIcon :icon="['far', 'search']" />
							</button>
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					class="ml-3"
					data-id="filter-button"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="bg-gray-100 rounded-sm pt-3 px-3 mb-3"
						data-id="show-filter-box"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									v-model="status"
									:options="PRODUCT_FAMILY_STATUS_OPTIONS"
									:allow-empty="false"
									:searchable="false"
									label="name"
									label-drop-down="Status"
									track-by="value"
									class="select-custom"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: pagination.lastPage,
						activePage: pagination.currentPage
					}"
					:fields="PRODUCT_FAMILY_TABLE_FIELDS"
					:items="list.data"
					striped
					hover
					fixed
					clickable-rows
					link-to="ProductFamilyEdit"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "product family" }) }}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge
							class="badge-status"
							:color="PRODUCT_FAMILY_STATUSES[item].class"
						>
							{{ PRODUCT_FAMILY_STATUSES[item].title }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { cleanObject, randomSearchString, hasSearchFilter } from '../assets/js/helpers';
import {
	PRODUCT_FAMILY_STATUSES,
	PRODUCT_FAMILY_STATUS_OPTIONS,
	PRODUCT_FAMILY_TABLE_FIELDS,
} from '../enums/productFamilies';

export default {
	name: 'ProductFamilyList',
	data() {
		return {
			PRODUCT_FAMILY_STATUSES,
			PRODUCT_FAMILY_STATUS_OPTIONS,
			PRODUCT_FAMILY_TABLE_FIELDS,

			isShowFilter: false,
			queryParams: {
				q: this.$route.query.q || null,
				is_visible: this.$route.query.is_visible,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('productFamilies', {
			list: 'list',
		}),

		...mapGetters({
			users: 'productFamily/productFamilyList',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		pagination() {
			return this.list.meta;
		},
		isCreateSuccess() {
			return this.create.isSuccess;
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		status: {
			get() {
				return PRODUCT_FAMILY_STATUS_OPTIONS.find((option) => option.value === this.queryParams.is_visible);
			},
			set(status) {
				this.queryParams.is_visible = status.value;

				this.updateUrlParams();
			},
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getProductFamilies(this.queryParams);
	},
	methods: {
		...mapActions({
			getProductFamilies: 'productFamilies/getProductFamilies',
		}),
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query });
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		getSelectedValue(selectedValue = null, options = []) {
			const optionValues = Object.values(options);
			const found = ({ value }) => value === selectedValue;

			return (
				optionValues.find(found) || {
					value: null,
					label: null,
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-wrapper {
		max-width: rem(132);
	}

	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}

	.icon-trash {
		color: $color-black-25;
	}

	::v-deep .col-name {
		z-index: 1;
	}
</style>
